/* Add this to your CSS file */
.footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
    font-family: "Fellix-SemiBold";
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "Fellix-SemiBold";
  }
  
  .contact-info {
    flex: 1;
    margin-right: 20px;
    margin-left: 20px;
    text-align: right;
    font-family: "Fellix-SemiBold";
    align-items: center;
    margin-bottom: 20px;
  }
  

  
  .address {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
 
    font-family: "Fellix-SemiBold";
  }
  
  .address svg {
    margin-right: 7px;

  }
  
  .address p {
    margin: 0;
    font-family: "Fellix-SemiBold";
  }
  
  .contact-details-div {
    margin: 0;
    display: flex;
    flex-direction: row;    
    justify-content: flex-end;
    font-family: "Fellix-SemiBold";
    align-items: center;
  }

  .contact-details-div svg {
    margin-right: 7px;
  }
  
  .legal-info {
    flex: 1;
    
  }
  
  .legal-info hr {
    border-top: 1px solid #fff;
    margin-bottom: 20px;
  }
  
  .legal-info p {
    margin-bottom: 5px;
    font-family: "Fellix-SemiBold";
  }
  
  .legal-info a {
    color: #fff;
    text-decoration: none;
    font-family: "Fellix-SemiBold";
    transition: all 0.4s ease-in-out;
  }
  
  .legal-info a:hover {
    text-decoration: underline;
  }
  