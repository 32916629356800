
/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .modal p {
    margin-bottom: 20px;
    font-family: 'Fellix-Bold';
  }
  
  .modal button {
    padding: 10px 20px;
    background: $color-accent; 
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Fellix-Bold';
  }
  
  .modal button:hover {
    background-color: darken($color-accent, 10%);
  }