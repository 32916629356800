// Define a color palette
$color-primary: #000; // Dark blue, representing professionalism
$color-secondary: #4D4D4D; // Grey for secondary elements
$color-accent: #bb1600; // Red accent color for calls to action
$color-background: #FAFAFA; // Light background to keep the site fresh and modern
$color-white: #FFFFFF; // Pure white for text on dark backgrounds
$font-stack: 'Segoe UI', 'Roboto', sans-serif; // A modern font stack

// Mixin for buttons
@mixin button-style($btn-color) {
  padding: 1rem 1rem;
  color: $color-white;
  background-color: $btn-color;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Fellix-Bold";
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  max-width: 270px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken($btn-color, 10%);
  }
}

// The rest of your SCSS variables and styles will follow after the mixin definition


// General body styles
body {
  font-family: $font-stack;
  color: $color-secondary;
  background-color: $color-background;
  line-height: 1.6;
  font-family: 'Fellix-Bold';
}



.h1-container{
  width: 80%;
  padding-bottom: 40px;
  margin: auto;
}

// The homepage structure
.home {

 

  .banner img {
    margin-top: 60px;
    width: 100%;
    height: auto;
    display: block; // This ensures there's no extra space under the image
    position: relative; // Ensure z-index is respected
    z-index: 10; // Higher than the surrounding elements
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  }

  
  .home-header {
    // Navigation styles go here
  }
  
  .home-main {
    .hero {
      background-color: white;
      color: black;
      padding: 4rem;
      text-align: center;

      h1 {
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        
      }

      .contact-info {
        margin-bottom: 2rem;
        
        a {
          color: $color-white;
          text-decoration: none;
          margin-right: 1rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }

    
    }

    .btn-contact {
      @include button-style($color-accent);
    }

 

    .about,
    .services,
    .testimonials,
    .resume {
      padding: 2rem 0;
      background-color: $color-white;
      margin: 0rem 0;

      h2 {
        color: $color-primary;
      }
    }

    .section-dark {
      color: white;
      background-color: black;

      h2 {
        color: white;
      }
    }

    .btn-more, .btn-services {
      @include button-style($color-primary);
      background-color: white;
     
      color: black;
      transition: all 0.2 ease-in-out;

      &:hover {
        background-color: rgb(216, 216, 216);
        scale: 1.01;
 
      }
    }
  }


  .home-footer {
    background-color: $color-primary;
    color: $color-white;
  }
}

// Mixin for buttons
@mixin button-style($btn-color) {
  padding: 1rem 2rem;
  color: $color-white;
  background-color: $btn-color;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s ease;


  &:hover {
    background-color: darken($btn-color, 10%);
  }
}

// Media query for mobile devices
@media (max-width: 768px) {

  .hero {
    padding: 2rem 0.8rem !important;
  }

  .h1-container{
    font-size: 2rem !important;
    width: 95%;
    padding-bottom: 20px;
  }
  .banner img {
    content: url("https://janssen-law.s3.us-east-2.amazonaws.com/JanssenBannerMobile.png");
  }
}



.outline {
border: 2px solid white;
border-radius: 2px;
  margin: 2rem auto;
  max-width: 800px; 
  padding: 2rem; // Padding inside the card


  p {
    font-family: "Fellix-Bold";
    font-size: 1.4rem;
  }



}


.resume {
  background: $color-background; // Light background to keep the site fresh and modern
  color: $color-secondary;
  padding: 2rem;
  font-family: "Fellix-Bold";

  .resume-container {
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    background: $color-white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  h1 {
    color: $color-primary;
    text-align: center;
    margin-bottom: 1rem;
    font-family: "Fellix-Bold";
  }

  .resume-section {
    margin-bottom: 2rem;

    h2 {
      border-bottom: 1px solid $color-secondary;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem;
      font-family: "Fellix-Bold";
    }

    ul {
      list-style-position: inside;
      padding-left: 0;
      
      li {
        margin-bottom: 0.5rem;
        font-family: "Fellix-SemiBold";
      }
    }

    p {
      text-align: justify;
      line-height: 1.6;
      font-family: "Fellix-SemiBold";
    }
  }

  .practice-areas {
    column-count: 2;
    column-gap: 1.5rem;
    
    li {
      break-inside: avoid-column; // Avoid breaking list items between columns
    }
  }
}


.law-categories {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 0;
}

  .card {
    flex-basis: calc(25% - 1rem); // Adjust based on your layout needs
    border-radius: 10px;
    margin: 0.5rem;

    background-size: cover;
    background-position: center;
 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; // Adjust as needed
    color: $color-white;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
    cursor: pointer;

 

    &:hover {
      transform: translateY(-10px);
    }
    h3 {
      position: relative; // Ensures text is above the overlay
      text-align: center;
      z-index: 1; // Ensures text is above the overlay
      text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8); // Adds a shadow to the text for better readability
    }

  }

  /* Media query for screens less than 768px */
@media (max-width: 768px) {
  .card {
    flex-basis: calc(100% - 1rem); /* One card per row on mobile devices */
  }
}
  
  

    .business {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://janssen-law.s3.us-east-2.amazonaws.com/business-law.jpg');
      background-size: cover;
      background-position: center;
    }
    
    .real-estate {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://janssen-law.s3.us-east-2.amazonaws.com/real-estate-law.png');
      background-size: cover;
      background-position: center;
    }
    
    .immigration {
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://janssen-law.s3.us-east-2.amazonaws.com/immigration-law.png');
      background-size: cover;
      background-position: center;
    }
    
    .wills-estates {
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url('https://janssen-law.s3.us-east-2.amazonaws.com/wills-estate-law.jpg');
      background-size: cover;
      background-position: center;
    }
    
    .enquiry-form {
      background-color: $color-background;
      padding: 4rem 0;
      font-family: "Fellix-Bold";
      .form-container {
        background: $color-white;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid $color-secondary;
        font-family: "Fellix-Bold";
    
        h2 {
          text-align: center;
          color: $color-primary;
          font-size: 2rem;
          margin-bottom: 2rem;
          font-family: "Fellix-Bold";
        }
    
        .form-group {
          margin-bottom: 1.5rem;
          label {
            display: block;
            color: $color-secondary;
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
            font-family: "Fellix-Bold";
            text-align: left;
          }
    
          input[type="text"],
          input[type="email"],    
          input[type="tel"] {
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            border: 2px solid $color-secondary;
            font-size: 1rem;

            &:focus {
              border: 2px solid $color-accent;
             
              outline: none;
            }
          }
    
          textarea {
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            border: 2px solid $color-secondary;
            height: 150px;
            font-size: 1rem;
            &:focus {
              border: 2px solid $color-accent;
            
              outline: none;
            }
          }
        }
    
        .submit-button {
          @include button-style($color-accent);
          width: 100%;
          font-size: 1.1rem;
          padding: 12px 0;
          transition: background-color 0.3s, transform 0.2s;
          font-family: "Fellix-Bold";
    
          &:hover {
            transform: translateY(-2px);
            background-color: darken($color-accent, 10%);
          }
        }
      }
    }
    
    .sent {
      background-color: #4caf50 !important; /* Green */
      color: white;
    }

    .feedback-message {
      font-family: "Fellix-Bold";
    }


    @media (max-width: 768px) { 
.outline 
{ max-width: 85%;
        padding: 10px;
      margin: 0 auto 20px;
    
    p {
      font-size: 1.2rem;
    }
    } 
    .resume-container 
{ max-width: 85% !important;
        padding: 10px !important;
      margin: 0 auto 20px !important;
    
  
    } 

    .enquiry-form 
    { max-width: 93% !important;
            padding: 10px !important;
          margin: 0 auto 20px !important;
        
      
        } 

        .form-container {
          padding: 10px !important;
        }

        input[type="text"],
        input[type="email"],    
        input[type="tel"] { 

          width: 93% !important;
        }

        textarea {
          width: 93% !important;
        }

        .footer-content {
          flex-direction: column;
          text-align: center;
        }

        .address {
          display: flex;
       margin: auto !important;
       padding: 10px;
       font-size: 0.9rem;
       justify-content: center;
       align-items: center;
          
        }

        .contact-info {
          display: flex;
          margin: auto !important;
          padding: 10px;
          font-size: 0.9rem;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .contact-details-div {
          font-size: 0.9rem;
          justify-content: center !important;
          align-items: center;
        }



    }