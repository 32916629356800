.navigation {
    position: sticky;
    top: 0;
    z-index: 999; // High z-index to keep it above other elements
    background-color: #fff; // Assuming a white background
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    width: 100%;
    position: fixed;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex; // Default display for desktop
        flex-direction: row;
        justify-content: space-between; // Align menu items for desktop
        align-items: center;
        width: 100%;
  
      li {
        margin: 0 10px;
      }
  
      a {
        text-decoration: none;
        color: #333;
        font-family: 'Fellix-Bold';
  
        &:hover {
          color: #555;
        }
      }
    }

    .row-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: auto 10px;
    }

    .contact-item {
        display: none; // Hide by default and show only in desktop view
        a {
          display: flex;
          align-items: center;
          svg {
            margin-right: 0.5rem;
          }
        }
      }

      .enquiry-nav-link {
        
            text-decoration: none;
            color: #333;
            font-family: 'Fellix-Bold';
            cursor: pointer;
      
            &:hover {
              color: #555;
            }
          
      }

    .phone-number {
        
        a {
          
          display: flex;
          align-items: center;
          justify-content: center;
          background: $color-accent; // Or any color you prefer
          border: solid  2px $color-accent; // Or any color you prefer

          color: $color-white;
          padding: 0.5rem 1rem;
          border-radius: 0.25rem;
          transition: all 0.3s ease;
        
    
          &:hover {
            background-color: darken($color-accent, 10%);
            border: solid  2px darken($color-accent, 10%); // Or any color you prefer

            color: white;
          }
    
          svg {
            margin-right: 0.5rem; // Space between the icon and the number
          }
        }
      }

      .email {
        
        a {
          
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid  2px $color-accent; // Or any color you prefer
          background-color: white;
          color: #000;
          padding: 0.5rem 1rem;
          border-radius: 0.25rem;
          transition: all 0.3s ease;
        
    
          &:hover {
            background-color: darken(white, 10%);

            color:black;
          }
    
          svg {
            margin-right: 0.5rem; // Space between the icon and the number
          }
        }
      }
  
    .menu-icon {
      display: none;
      cursor: pointer;
  
      .hamburger {
        width: 30px;
        height: 3px;
        background-color: #333;
        position: relative;
        transition: all 0.3s ease;
        z-index: 100;
        border-radius: 20px;
  
      // Reset the stacking context for pseudo-elements
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0px;
      width: 100%;
      height: 3px;
      background-color: #333;
      transition: all 0.3s ease;
      border-radius: 20px;
    }
  
        &::before {
          top: -8px;
        }
  
        &::after {
          top: 8px;
        }
  
        &.open {
          background: transparent;
  
          &::before {
            transform: rotate(45deg);
            top: 0;
          }
  
          &::after {
            transform: rotate(-45deg);
            top: 0;
          }
        }
      }
    }

    
  

  
    @media (max-width: 768px) {


        padding: 30px;

        .row-wrapper {
        flex-direction: column;
  
   
        }
     

      .menu-icon {
        display: block;
      }
  
      .menu {
        position: fixed;

        top: 0px; // Adjust based on your header height
        left: 0;
        right: 0;
        display: none;
        flex-direction: column;
        background: #fff; // Match the background to your nav bar
        box-shadow: 0 4px 5px rgba(0,0,0,0.2);
  
        li {
          text-align: center;
          padding: 15px 0;
        }

        .contact-item {
          display: block; // Show on desktop
          width: 100%;
        }
  
        &.open {
          display: flex;
        }
      }
    }

    @media (min-width: 768px) { // Assuming 768px is the breakpoint for desktop
        .menu {
          justify-content: space-between;
    
          .contact-item {
            display: block; // Show on desktop
          }
    
          .phone-number, .email {
            margin-left: auto; // Moves to the right side
          }
        }
      }
  }
  

